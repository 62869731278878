// ./pages/[...wordpressNode].js
import { getFaustStaticPaths } from '@/utils/getFaustStaticPaths';
import { getWordPressProps, WordPressTemplate } from '@faustwp/core';
import { TWENTY_FOUR_HOURS } from '@/constants/revalidationInterval';

export default function Page(props) {
  return <WordPressTemplate {...props} />;
}

export function getStaticProps(ctx) {
  return getWordPressProps({
    ctx, 
    revalidate: TWENTY_FOUR_HOURS
  });
}

export async function getStaticPaths() {
  if (process.env.NODE_ENV === 'development' || process.env.ENABLE_BUILD_SHORTCUTS === 'true') {
    return { paths: [], fallback: 'blocking' };
  }

  return await getFaustStaticPaths();
}